<template>
  <div class="athlete-suggestion" @click="$emit('click')" v-bind="$attrs">
    <v-avatar class="athlete-avatar" :size="size" v-bind="$attrs" :class="{'is-default': isDefault}">
      <img :src="persona.src || DefaultAvatar" @error="fallbackAvatar" alt="" />
    </v-avatar>
    <span class="athlete-name">
      {{ `${persona.first_name} ${persona.last_name}` }}
    </span>
  </div>
</template>

<script>
import DefaultAvatar from "@/assets/athlete_default_avatar.svg";

export default {
  name: "AthleteSuggestion",
  props: {
    persona: {
      type: Object,
      default: () => {}
    },
    size: {
      type: Number,
      default: 24
    },
  },
  data() {
    return {
      DefaultAvatar,
      isDefault: !this.persona.src
    }
  },
  methods: {
    fallbackAvatar(e) {
      if (e.target && e.target.src) {
        this.isDefault = true
        e.target.src = DefaultAvatar;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.athlete-suggestion {
  display: inline-flex;
  align-items: center;
  min-width: 0;
  max-width: 232px;
  height: 44px;
  background-color: #242424;
  border-radius: 30px;
  padding: 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;


  .athlete-avatar {
    border: 1px solid white;
    box-sizing: border-box;

    &.is-default {
      border: 1px solid transparent;
    }
    & + .athlete-name {
      margin-left: 8px;
    }
  }

  .athlete-name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: white;
    max-width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover,
  &:active {
    cursor: pointer;
    background-color: #333333;
  }

  @media screen and (max-width: 499px) {
    height: 36px;

    .athlete-name {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
    }
  }
}
</style>
