<template>
  <v-select
    v-model="fieldValue"
    :items="items"
    class="input-field base-select"
    :label="$t(label)"
    :item-value="itemValue"
    append-icon="keyboard_arrow_down"
    v-bind="$attrs"
  >
    <template slot="item" slot-scope="{ item }">
      {{ $t(item[itemText]) }}
    </template>
    <template slot="selection" slot-scope="{ item }">
      {{ $t(item[itemText]) }}
    </template>
  </v-select>
</template>

<script>
export default {
  name: "BaseSelect",
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    fieldValue: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(val);
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/sass/variables";

.base-select {
  position: relative;

  ::v-deep(.v-label) {
    width: 100%;
    max-width: 100%;
    transform-origin: top left;

    .label {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .danger {
        color: #cf1019;
      }
    }

    &.v-label--active {
      transform: translateY(-18px) scale(0.75);
      width: 133%;
      max-width: 133%;
    }
  }
}
</style>
