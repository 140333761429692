<template>
  <div class="input-field base-datepicker">
    <!-- desktop input -->
    <v-menu
      v-if="!useDevicePicker"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :value="computedDateFormat"
          :rules="rules"
          readonly
          :label="$t(label)"
          v-on="on"
          append-icon="keyboard_arrow_down"
          v-on:click:append="menu = true"
          v-bind="$attrs"
        />
      </template>
      <v-date-picker
        v-model="model"
        @change="menu = false"
        :locale="userLanguage"
        :max="maxDate"
        :min="minDate"
        :active-picker="activePicker"
      />
    </v-menu>
    <!-- mobile input -->
    <v-text-field
      v-if="useDevicePicker"
      v-model="model"
      type="date"
      :rules="rules"
      :label="$t(label)"
      append-icon="keyboard_arrow_down"
      class="mobile-picker"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "BaseDatePicker",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    isKid: {
      type: Boolean,
      default: false,
    },
    isBirthday: {
      type: Boolean,
      default: false,
    },
    // activePicker: {
    //   type: String,
    //   default: "YEAR",
    // },
  },
  data() {
    return {
      menu: false,
      useDevicePicker: false,
      activePicker: null,
    };
  },
  computed: {
    ...mapGetters(["userLanguage", "specificLinks"]),
    computedDateFormat() {
      // TODO: Add another date format ( moment(this.value).format("DD MMMM, YYYY") )
      if (this.value) return moment(this.value, "YYYY-MM-DD").format("MMMM DD, YYYY");
      return "";
    },
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    maxDate() {
      let limit = moment();
      if (!this.isBirthday) {
        limit = limit.add(10, "year");
      }
      return limit.format("YYYY-MM-DD");
    },
    minDate() {
      let limit = moment();
      if (this.isBirthday) {
        limit = limit.subtract(120, "year");
      }
      return limit.format("YYYY-MM-DD");
    },
  },
  watch: {
    menu(val) {
      val && !this.model && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.useDevicePicker = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile-picker {
  ::v-deep {
    .v-text-field__slot {
      z-index: 1;

      input {
        text-align: left;
        &::-webkit-date-and-time-value {
          text-align: left;
        }
      }
    }
  }
}
</style>
