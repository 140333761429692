<template>
  <div class="page page--ticket-edit">
    <div
      class="back-button"
      @click="$router.push({ name: 'eventPage', params: $route.params.id })"
    >
      <i class="material-icons">keyboard_arrow_left</i>
      <span>{{ $t("back") }}</span>
    </div>
    <div class="mem-font--claim-ticket__title list-title">
      {{ $t("athlete_details") }}
    </div>
    <v-form
      ref="form"
      class="edit-form"
      lazy-validation
      @submit.prevent=""
      :key="renderCount"
      autocomplete="off"
    >
      <div class="card ticket-edit-form">
        <div class="card__body">
          <div class="previous_athletes" v-if="showPreviousAthletes">
            <span class="previous_title">
              {{ $t("select_previos_athlete_title") }}:
            </span>
            <div class="suggestions">
              <athlete-suggestion
                class="suggestion_item"
                v-for="(persona, key) in attendees"
                :key="key"
                :persona="persona"
                @click="fillFormFromSuggestion(persona)"
              />
              <div
                v-if="showAttendeesMoreButton"
                class="show-more"
                @click="showAttendeesMoreButton = false"
              >
                <v-icon class="more-icon"> more_horiz </v-icon>
                <span class="more-title">{{
                  $t("see_all") | capitalize("first")
                }}</span>
              </div>
            </div>
            <span class="previous_title">
              {{ $t("add_athlete_manually_title") }}:
            </span>
          </div>
          <span class="card__description" v-if="showSubtitle">
            {{ $t("athlete_details_subtitle") }}
          </span>

          <base-input
            v-model.trim="form.firstName"
            :label="$t('first_name')"
            class="input-field"
            :disabled="
              ticketType === TICKET_TYPES.myself ||
              ticketType === TICKET_TYPES.pass
            "
            :rules="rules.firstName"
            :error="!!errors.firstName"
            :error-messages="errors.firstName"
            validate-on-blur
            data-testid="first-name"
            autocomplete="off"
          />

          <base-input
            v-model.trim="form.lastName"
            :label="$t('last_name')"
            class="input-field"
            :disabled="
              ticketType === TICKET_TYPES.myself ||
              ticketType === TICKET_TYPES.pass
            "
            :rules="rules.lastName"
            :error="!!errors.lastName"
            :error-messages="errors.lastName"
            validate-on-blur
            data-testid="last-name"
            autocomplete="off"
          />

          <base-select
            v-model="form.gender"
            :items="GENDERS"
            :rules="rules.gender"
            :error="!!errors.gender"
            :error-messages="errors.gender"
            item-text="full"
            item-value="short"
            label="gender"
            data-testid="gender"
          />

          <!-- birthday -->
          <base-date-picker
            v-model="form.birthDate"
            label="birth_date"
            :rules="birthDateValidation"
            :error="!!errors.birthDate"
            :error-messages="errors.birthDate"
            is-birthday
            data-testid="birth-date"
          />

          <base-input
            v-if="showGuardianName"
            v-model="form.guardianName"
            :label="$t('guardian_name')"
            class="input-field"
            :rules="rules.requireValue"
            validate-on-blur
            data-testid="guardian-name"
          />

          <mem-phone-field
            :label="$t('phone_number')"
            v-model="form.phone"
            :phone-data="parsedFields.mobilePhone"
            :phone-country="userData.spartan_country_code"
            data-testid="phone-number"
            :menu-offset="16"
          ></mem-phone-field>

          <!-- address fields -->
          <template v-if="showAddressFields">
            <mem-address-field
              v-model.trim="form.address"
              :label="$t('street_address')"
              class="input-field"
              :rules="rules.requireValue"
              :error="!!errors.address"
              validate-on-blur
              data-testid="street-address"
              @on-place-change="fillAddress"
            />
            <base-select
              v-model="form.country"
              :label="$t('country')"
              :items="countries"
              item-text="value"
              item-value="id"
              :rules="rules.requireValue"
              :error="!!errors.country"
              data-testid="country"
            ></base-select>
            <base-select
              v-if="showStatesList"
              v-model="form.state"
              :label="$t('state')"
              data-testid="state"
              :items="statesList"
              item-text="value"
              item-value="id"
              :rules="rules.requireValue"
              :error="!!errors.state"
            ></base-select>
            <base-input
              v-model.trim="form.city"
              :label="$t('city')"
              class="input-field"
              :rules="rules.requireValue"
              :error="!!errors.city"
              validate-on-blur
              data-testid="city"
            ></base-input>
          </template>

          <base-input
            v-model.trim="form.zip"
            :label="$t('zip_code')"
            class="input-field"
            :rules="rules.zip"
            :error="!!errors.zip"
            :error-messages="errors.zip"
            validate-on-blur
            data-testid="zip"
          ></base-input>

          <base-input
            v-if="showEmail"
            v-model.trim="form.email"
            :label="$t('email')"
            type="email"
            class="input-field"
            :rules="rules.email"
            :error="!!errors.email"
            :error-messages="errors.email"
            validate-on-blur
            data-testid="email"
          />

          <v-select
            v-if="showTShirt"
            v-model="form.tShirt"
            :items="T_SHIRT_SIZES"
            :label="$t('t_shirt_size')"
            class="input-field base-select"
            :rules="rules.tShirt"
            :error="!!errors.tShirt"
            :error-messages="errors.tShirt"
            append-icon="keyboard_arrow_down"
            data-testid="t-shirt-size"
          ></v-select>

          <template v-if="showEmergency">
            <base-input
              v-model.trim="form.emergencyContactName"
              :label="$t('emergency_contact_name')"
              class="input-field"
              :rules="rules.emergencyContactName"
              :error="!!errors.emergencyContactName"
              :error-messages="errors.emergencyContactName"
              validate-on-blur
              data-testid="emergency-contact-name"
            />
            <mem-phone-field
              :label="$t('emergency_contact_phone')"
              v-model="form.emergencyContactPhone"
              :phone-data="parsedFields.emergencyContactPhone"
              :phone-country="userData.spartan_country_code"
              :menu-offset="16"
              data-testid="emergency-contact-phone"
            ></mem-phone-field>
          </template>

          <v-select
            v-if="showNationality"
            v-model="form.nationality"
            :label="$t('nationality')"
            class="input-field base-select"
            :items="nationalities"
            :rules="rules.requireValue"
            :error="!!errors.nationality"
            append-icon="keyboard_arrow_down"
            data-testid="nationality"
          ></v-select>

          <div class="bottom-text">
            <div v-if="showAttentiveSection" class="attentive-section">
              <div class="section-line">
                <mem-checkbox v-model="attentiveTransactional"></mem-checkbox>
                <div>{{ $t("attentive_transactional_text") }}</div>
              </div>
              <div class="section-line">
                <mem-checkbox v-model="attentiveMarketing"></mem-checkbox>
                <div>{{ $t("attentive_marketing_text") }}</div>
              </div>
              <i18n path="assign_ticket_bottom_text" tag="span">
                <a
                  href="https://www.spartan.com/en/about/terms-of-service"
                  target="_blank"
                  >{{ $t("terms_and_conditions") }}</a
                >
                <a
                  href="https://www.spartan.com/en/about/privacy-policy"
                  target="_blank"
                  >{{ $t("privacy_policy") }}</a
                >
              </i18n>
            </div>
            <span v-else>
              {{ $t("athlete_details_description") }}
            </span>
          </div>

          <div class="buttons">
            <v-btn
              class="button-submit"
              :disabled="!isFormValid"
              :loading="loading"
              color="#FFFFFF"
              depressed
              rounded
              large
              dark
              @click="onSave"
            >
              <span class="action__desktop">{{ $t("save") }}</span>
            </v-btn>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
import { isEmpty, upperCase, each } from "lodash";
import { mapActions, mapGetters } from "vuex";
import parsePhoneNumber from "libphonenumber-js";

import { countries, states, nationalities } from "@/lists";
import { GENDERS, T_SHIRT_SIZES } from "@/common/const";

import {
  emailValidator,
  requireValidator,
  numbersValidator,
} from "@/common/validators";
import { GooglePlaces } from "@/common/helpers";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseSelect from "@/components/base/BaseSelect";
import BaseDatePicker from "@/components/base/BaseDatePicker";
import AthleteSuggestion from "@/components/AthleteSuggestion";

const TICKET_TYPES = {
  myself: "myself",
  someone_else: "someone_else",
  kid: "kid",
  pass: "pass",
  pass_someone_else: "pass_someone_else",
};

export default {
  name: "TicketEditView",
  components: {
    AthleteSuggestion,
    BaseDatePicker,
    BaseInput,
    BaseSelect,
    "mem-address-field": () =>
      import("@/components/base/BaseAddressSearch.vue"),
    "mem-phone-field": () => import("@/components/base/BasePhoneFieldV2.vue"),
    "mem-checkbox": () => import("@/components/base/BaseCheckbox.vue"),
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        gender: "",
        birthDate: "",
        guardianName: "",
        phone: "",
        zip: "",
        email: "",
        tShirt: "",
        emergencyContactName: "",
        emergencyContactPhone: "",
        type: "",
        address: "",
        country: "",
        state: "",
        city: "",
        nationality: "",
      },
      rules: {
        // TODO: Replace duplicate required validators with single one
        firstName: [
          (val) => requireValidator(val) || this.$t("field_required"),
        ],
        lastName: [(val) => requireValidator(val) || this.$t("field_required")],
        gender: [(val) => requireValidator(val) || this.$t("field_required")],
        phone: [
          (val) => requireValidator(val) || this.$t("field_required"),
          (val) => numbersValidator(val) || this.$t("invalid_phone_number"),
        ],
        zip: [(val) => requireValidator(val) || this.$t("field_required")],
        email: [
          (val) => requireValidator(val) || this.$t("field_required"),
          (val) => emailValidator(val) || this.$t("invalid_email"),
        ],
        tShirt: [(val) => requireValidator(val) || this.$t("field_required")],
        emergencyContactName: [
          (val) => requireValidator(val) || this.$t("field_required"),
        ],
        emergencyContactPhone: [
          (val) => requireValidator(val) || this.$t("field_required"),
          (val) => numbersValidator(val) || this.$t("invalid_phone_number"),
        ],
        requireValue: [
          (val) => requireValidator(val) || this.$t("field_required"),
        ],
      },
      errors: {},
      loading: false,
      showAttendeesMoreButton: false,
      placeAutocomplete: null,
      selectedAddress: {},
      countries,
      states,
      nationalities,
      // consts
      T_SHIRT_SIZES,
      GENDERS,
      TICKET_TYPES,
      renderCount: 1,
      // TODO: Need to find out why It work and how to change it.
      isMounted: false,

      attentiveTransactional: true,
      attentiveMarketing: false,
      isAttentivePhone: false,
      parsedFields: {
        mobilePhone: null,
        emergencyContactPhone: null,
      },
      athleteAge: 0,
    };
  },
  computed: {
    ...mapGetters(["userData", "currentTicket", "ticketAttendees"]),
    ticketType() {
      const ticketType = this.currentTicket?.reg_choice_name || "";

      if (
        ticketType &&
        (ticketType.toLowerCase().split(" ").includes("pass") ||
          ticketType.toLowerCase().split(" ").includes("parking"))
      ) {
        if (this.$route.params?.type === "someone_else")
          return TICKET_TYPES.pass_someone_else;
        return TICKET_TYPES.pass;
      }
      return this.$route.params?.type || null;
    },
    ticketId() {
      return this.$route.params?.barcode || null;
    },
    eventId() {
      return this.$route.params?.id || null;
    },
    attendees() {
      if (!this.ticketAttendees) return [];
      const result = this.ticketAttendees.map((item) => ({
        ...item,
        src: item?.avatar?.medium,
        first_name: item?.ts_attendee_first_name,
        last_name: item?.ts_attendee_last_name,
      }));
      if (this.showAttendeesMoreButton) return result.slice(0, 3);
      return result;
    },
    isFormValid() {
      if (!this.isMounted) return;

      const requireField = { ...this.form };

      let formPhone = parsePhoneNumber(`+${this.form.phone}`);
      let formEmergencyPhone = parsePhoneNumber(
        `+${this.form.emergencyContactPhone}`
      );
      let isValidEmergencyPhone = formEmergencyPhone?.isValid();

      let { form } = this.$refs;
      delete requireField.type;
      if (!this.showEmail) delete requireField.email;
      if (!this.showTShirt) delete requireField.tShirt;
      if (!this.showEmergency) {
        delete requireField.emergencyContactName;
        delete requireField.emergencyContactPhone;
        isValidEmergencyPhone = true;
      }
      if (!this.showNationality) delete requireField.nationality;

      if (!this.showAddressFields) {
        delete requireField.address;
        delete requireField.country;
        delete requireField.state;
        delete requireField.city;
      }
      if (this.showAddressFields && !this.showStatesList)
        delete requireField.state;

      if (!this.showGuardianName) {
        delete requireField.guardianName;
      }

      return (
        Object.keys(requireField).every((key) => !!this.form[key]) &&
        isEmpty(this.errors) &&
        form?.validate() &&
        formPhone?.isValid() &&
        isValidEmergencyPhone
      );
    },
    showPreviousAthletes() {
      return (
        (this.ticketType === TICKET_TYPES.someone_else ||
          this.ticketType === TICKET_TYPES.kid) &&
        this.attendees.length
      );
    },
    showSubtitle() {
      return (
        this.ticketType !== TICKET_TYPES.someone_else &&
        this.ticketType !== TICKET_TYPES.pass_someone_else &&
        this.ticketType !== TICKET_TYPES.kid
      );
    },
    showEmail() {
      return (
        this.ticketType === TICKET_TYPES.someone_else ||
        this.ticketType === TICKET_TYPES.pass_someone_else
      );
    },
    showTShirt() {
      return (
        this.ticketType !== TICKET_TYPES.kid &&
        this.ticketType !== TICKET_TYPES.pass &&
        this.ticketType !== TICKET_TYPES.pass_someone_else
      );
    },
    showEmergency() {
      return (
        this.ticketType !== TICKET_TYPES.pass &&
        this.ticketType !== TICKET_TYPES.pass_someone_else
      );
    },
    birthDateValidation() {
      const rules = [
        (val) => requireValidator(val) || this.$t("field_required"),
      ];

      rules.push(() => {
        if (this.athleteAge < this.currentTicket?.reg_choice_min_age)
          return this.$t("ticket_minimum_age", {
            age: this.currentTicket.reg_choice_min_age,
          });
        if (this.athleteAge > this.currentTicket?.reg_choice_max_age)
          return this.$t("ticket_maximum_age", {
            age: this.currentTicket.reg_choice_max_age,
          });
        return true;
      });

      return rules;
    },
    showStatesList() {
      let countries = ["US", "CA", "AU"];
      return countries.includes(this.form.country);
    },
    statesList() {
      let code = this.form.country;
      let countries = ["US", "CA", "AU"];
      if (!code || !countries.includes(code)) return [];
      return this.states.find((country_code) => country_code.name === code)[
        code
      ];
    },
    // display share modal after save
    showShareModal() {
      let TARGET_COUNTRIES = ["US", "USTR"];
      return (
        this.ticketType === "myself" &&
        TARGET_COUNTRIES.includes(this.currentTicket.country)
      );
    },
    showAttentiveSection() {
      let countries = ["US", "GB", "CA"];
      return (
        countries.includes(upperCase(this.currentTicket?.country)) &&
        this.isAttentivePhone
      );
    },
    showNationality() {
      let ticketCountries = ["CH", "EU", "MT"];
      return ticketCountries.includes(upperCase(this.currentTicket?.country));
    },
    showAddressFields() {
      return (
        this.ticketType !== TICKET_TYPES.pass &&
        this.ticketType !== TICKET_TYPES.pass_someone_else
      );
    },
    showGuardianName() {
      if (this.athleteAge === 0) return;

      let ticketCountries = ["CH", "EU"];
      return (
        this.athleteAge < 18 &&
        ticketCountries.includes(upperCase(this.currentTicket?.country))
      );
    },
  },
  methods: {
    ...mapActions([
      "updateTicketAttendee",
      "handleToolbarNotification",
      "getTicketAttendees",
    ]),
    async onSave() {
      if (this.loading) return;
      this.loading = true;
      this.errors = {};
      const { form } = this.$refs;
      if (form?.validate()) {
        const form = this.prepareForm();
        try {
          await this.updateTicketAttendee({ ticketId: this.ticketId, form });
          this.handleToolbarNotification({
            type: "success",
            text: "ticket_will_update",
            isVisible: true,
            closeTimeout: 3000,
          });
          await this.$router.push({
            name: "eventPage",
            params: { id: this.eventId, showShareModal: this.showShareModal },
          });
        } catch (error) {
          console.log(error, "Claim ticket error", error);
          this.handleToolbarNotification({
            type: "warning",
            text: "server_error",
            isVisible: true,
            closeTimeout: 3000,
          });
        }
      }
      this.loading = false;
    },
    prepareForm() {
      let form = { ...this.form };

      if (this.ticketType === TICKET_TYPES.kid) {
        form.email = this.userData.email;
      }
      if (this.showAttentiveSection) {
        form.attentiveTransactional = this.attentiveTransactional;
        form.attentiveMarketing = this.attentiveMarketing;
      }
      if (!this.showGuardianName) {
        delete form.guardianName;
      }

      return form;
    },
    fillFormFromUserData() {
      this.form.firstName = this.userData?.first_name;
      this.form.lastName = this.userData?.last_name;
      this.form.birthDate = this.userData?.birth_date;

      let { code, number } = this.userData.mobile_phone_parsed || {};
      if (code && number) this.form.phone = `${code}${number}`;
      else this.form.phone = "";
      this.parsedFields.mobilePhone = this.userData.mobile_phone_parsed;

      this.form.zip = this.userData?.zip;
      this.form.email = this.userData?.email;
      this.form.gender = this.userData?.gender;
      if (this.showTShirt) this.form.tShirt = this.userData?.tshirt_size;
      if (this.showEmergency) {
        this.form.emergencyContactName = this.userData?.emergency_contact_name;
        this.form.emergencyContactPhone =
          this.userData?.emergency_contact_phone;
        this.parsedFields.emergencyContactPhone =
          this.userData.emergency_contact_phone_parsed;
      }
      if (this.showNationality)
        this.form.nationality = this.userData?.nationality;

      if (this.showAddressFields) {
        this.form.address = this.userData?.address1;
        this.form.country = this.userData?.country_code;
        this.form.state = this.userData?.state;
        this.form.city = this.userData?.city;
      }
      this.renderCount += 1;
    },
    fillFormFromSuggestion(persona) {
      this.form.firstName = persona?.first_name || "";
      this.form.lastName = persona?.last_name || "";
      this.form.birthDate = persona?.ts_attendee_dob || "";
      this.form.phone = persona?.ts_attendee_phone || "";
      this.parsedFields.mobilePhone = persona?.ts_attendee_phone_parsed || "";
      this.form.zip = persona?.zip || "";
      this.form.email = persona?.ts_attendee_email || this.userData?.email;
      this.form.gender = persona?.ts_attendee_gender || "";
      if (this.showTShirt)
        this.form.tShirt = persona?.ts_attendee_tshirt_size || "";
      if (this.showEmergency) {
        this.form.emergencyContactName =
          persona?.ts_attendee_emergency_name || "";
        this.form.emergencyContactPhone =
          persona?.ts_attendee_emergency_phone || "";
        this.parsedFields.emergencyContactPhone =
          persona?.ts_attendee_emergency_phone_parsed || "";
      }
      if (this.showNationality)
        this.form.nationality = persona?.nationality || "";

      if (this.showAddressFields) {
        this.form.address = persona?.address || "";
        this.form.country = persona?.country || "";
        this.form.state = persona?.state || "";
        this.form.city = persona?.city || "";
      }
      this.renderCount += 1;
    },
    fillAddress(address) {
      if (!address) return;
      if (address && !address.formatted) return;

      let { state_code, city, postcode, country_code } = address;
      let ISO_COUNTRY_CODE = country_code || "";

      // form

      // country
      this.form.country = ISO_COUNTRY_CODE;
      // state
      if (ISO_COUNTRY_CODE === "AU" && state_code) {
        this.form.state = `AU-${state_code}`;
      } else {
        this.form.state = state_code || "";
      }
      // city
      this.form.city = city || "";
      // zip
      this.form.zip = postcode || "";
    },
    onPlaceChange() {
      // Get the place details from the autocomplete object.
      let place = this.placeAutocomplete.getPlace();
      // console.log(place, "place");

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (GooglePlaces.componentForm[addressType]) {
          var val =
            place.address_components[i][
              GooglePlaces.componentForm[addressType]
            ];
          this.selectedAddress[addressType] = val;
        }
      }

      this.fillAddress();
    },
    fillAddressLEGACY() {
      // address
      if (this.selectedAddress.street_number && this.selectedAddress.route) {
        this.form.address = document.getElementById("autocomplete").value =
          this.selectedAddress.street_number + " " + this.selectedAddress.route;
      } else {
        this.form.address = document.getElementById("autocomplete").value;
      }

      // country
      if (this.selectedAddress.country) {
        this.form.country = this.selectedAddress.country;
      } else this.form.country = "";
      // state
      if (this.selectedAddress.administrative_area_level_1) {
        if (this.selectedAddress.country === "AU")
          this.form.state =
            "AU-" + this.selectedAddress.administrative_area_level_1;
        else this.form.state = this.selectedAddress.administrative_area_level_1;
      } else this.form.state = "";
      // city
      if (this.selectedAddress.locality) {
        this.form.city = this.selectedAddress.locality;
      } else this.form.city = "";
      // zip
      if (this.selectedAddress.postal_code)
        this.form.zip = this.selectedAddress.postal_code;
      else this.form.zip = "";
    },
    validateNumber(number, country) {
      const phoneNumber = parsePhoneNumber(number, country);
      return phoneNumber?.isValid();
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (!Object.values(TICKET_TYPES).includes(this.ticketType)) {
          this.$router.push(`/events/${this.eventId}`);
        }
        // ???
        if (
          !(
            this.currentTicket?.is_purchased_by_me ||
            this.currentTicket?.is_mine
          )
        ) {
          this.$router.push(`/events/${this.eventId}`);
        }
      },
    },
    userData: {
      immediate: true,
      async handler() {
        if (
          this.ticketType !== TICKET_TYPES.someone_else &&
          this.ticketType !== TICKET_TYPES.pass_someone_else &&
          this.ticketType !== TICKET_TYPES.kid
        ) {
          this.fillFormFromUserData();
          this.form.type = "yourself";
        }
        if (this.ticketType === TICKET_TYPES.kid) {
          this.form.type = "kid";
          await this.getTicketAttendees({ isKid: true });
        }
        if (this.ticketType === TICKET_TYPES.someone_else) {
          this.form.type = "manually";
          await this.getTicketAttendees({ isKid: false });
        }
        if (this.ticketType === TICKET_TYPES.pass_someone_else) {
          this.form.type = "manually";
        }
      },
    },
    ticketAttendees() {
      this.showAttendeesMoreButton = this.ticketAttendees.length > 3;
    },
    "form.phone": {
      immediate: true,
      handler(phone) {
        let countries = ["US", "GB", "CA"];
        this.isAttentivePhone = false;

        each(countries, (country) => {
          if (this.isAttentivePhone) return;
          this.isAttentivePhone = this.validateNumber(phone, country);
        });
      },
    },
    "form.birthDate": {
      immediate: true,
      handler(date) {
        if (!date) return;
        this.athleteAge = moment().diff(moment(date), "year");
      },
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss" scoped>
.page--ticket-edit {
  padding-bottom: 180px;

  .back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;

    position: relative;
    font-weight: bold;
    font-size: 14px;
    @include cursorPointer;

    // margin-bottom: 40px;
    span {
      line-height: 14px;
      margin-left: 20px;
    }

    text-transform: uppercase;

    i {
      position: absolute;
      left: -8px;
    }
  }

  .list-title {
    margin: 24px 0 36px 0;
  }

  .card {
    display: flex;
    flex-direction: column;
    max-width: min(722px, 100%);
    width: 100%;
    background: #121212;
    border-radius: 5px;
    padding: 36px 32px 24px;
    align-items: center;
    margin: 0;

    & + .card {
      margin-top: 8px;
    }

    &__body {
      max-width: 413px;
      width: 100%;
      padding: 16px 0 34px 0;
      .bottom-text {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 20px;

        padding-top: 22px;
        padding-bottom: 45px;
        @media screen and (max-width: 499px) {
          padding-top: 14px;
          max-width: 86%;
        }
        .attentive-section {
          display: flex;
          flex-direction: column;
          .section-line {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            min-height: 34px;
            font-size: 12px;
            line-height: 17px;
            margin-bottom: 22px;
            .mem-checkbox {
              margin-top: 4px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .ticket-edit-form {
    .card__subtitle {
      display: block;
      font-size: 14px;
      color: #757474;
      margin-bottom: 13px;
      font-weight: 600;
    }

    .card__description {
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      color: white;
      font-weight: 600;
      margin-bottom: 50px;
    }

    .input-field {
      ::v-deep .v-label {
        top: 0;
        text-transform: capitalize;

        &.v-label--active {
          top: 5px;
        }
      }

      ::v-deep .v-input__slot::before {
        border-image: none;
      }

      &.input-error {
        .v-label {
          color: #cf1019;
          text-align: center;
          height: 20px;
          font-weight: bold;
        }
      }

      &.base-select {
        ::v-deep .v-input__append-inner .v-icon {
          margin-bottom: 22px;
          font-size: 30px;
        }
      }

      &.base-datepicker {
        ::v-deep .v-input__append-inner .v-icon {
          margin-bottom: 22px;
          font-size: 30px;
        }
      }

      &.base-textarea {
        ::v-deep .v-label {
          top: 2px;

          &.v-label--active {
            top: 7px;
          }
        }
      }

      & + .input-field {
        margin-top: 5px;
      }
    }

    .base-select {
      position: relative;

      ::v-deep(.v-label) {
        width: 100%;
        max-width: 100%;
        transform-origin: top left;

        .label {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .danger {
            color: #cf1019;
          }
        }

        &.v-label--active {
          transform: translateY(-18px) scale(0.75);
          width: 133%;
          max-width: 133%;
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .button-submit {
        display: flex;
        flex: 1 0;
        max-width: 138px;
        color: #020000;
        margin-left: auto;

        &::v-deep(.v-btn--disabled) {
          background-color: rgba(255, 255, 255, 0.2) !important;
          color: black !important;
        }

        &.button-delete,
        &.button-cancel {
          color: white;
        }

        &.button-cancel + .button-submit {
          margin-left: 14px;
        }

        &.button-delete {
          margin-left: 0;
        }

        .action__mobile {
          display: none;
        }
      }
    }

    .previous_athletes {
      display: flex;
      flex-direction: column;
      justify-content: left;

      .previous_title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 21px;
      }

      .suggestions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -8px;

        .suggestion_item {
          margin-bottom: 8px;
          margin-left: 8px;

          & + .show-more {
            margin-left: 8px;
          }
        }

        .show-more {
          display: inline-flex;
          align-items: center;
          height: 44px;
          background-color: #242424;
          border-radius: 30px;
          padding: 0 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          user-select: none;
          color: white;

          .more-icon {
            color: #bdbdbd;
            & + .more-title {
              margin-left: 8px;
            }
          }

          .more-title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
          }

          &:hover {
            cursor: pointer;
            background-color: #333333;
          }
        }

        & + .previous_title {
          margin-top: 56px;
        }
      }
    }
  }
}

@media screen and (max-width: 499px) {
  .page--ticket-edit {
    padding-bottom: 90px;

    .back-button {
      font-size: 16px;
      margin-left: 16px;
      margin-top: 30px;
      margin-bottom: 0;
    }

    .list-title {
      margin: 16px 16px 40px 16px;
    }

    .card {
      padding: 0 24px 24px;
      background: #000000;

      &__body {
        padding: 0;
        max-width: 100%;
      }
    }

    .ticket-edit-form {
      padding: 0 16px;

      .card__description {
        max-width: 80%;
        font-size: 16px;
        margin-bottom: 44px;
      }

      .input-field {
        & + .input-field {
          margin-top: 3px;
        }
      }

      .buttons {
        padding: 0 16px;
        justify-content: center;

        .button-submit {
          margin: 0;
          padding: 0 5px;
          max-width: 212px;

          .action__desktop {
            font-size: 12px;
          }

          &.button-cancel {
            margin-left: 0;
          }
        }
      }

      .previous_athletes {
        .previous_title {
          margin-bottom: 25px;
        }

        .suggestions {
          margin-left: -5px;
          .suggestion_item {
            margin-bottom: 7px;

            & + .show-more {
              margin-left: 5px;
            }
          }

          .show-more {
            height: 36px;

            .more-icon {
              & + .more-title {
                margin-left: 5px;
              }
            }
          }

          & + .previous_title {
            margin-top: 40px;
          }
        }
      }
    }
  }
}
</style>
